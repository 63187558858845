import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nos-implantations',
  templateUrl: './nos-implantations.component.html',
  styleUrls: ['./nos-implantations.component.scss']
})
export class NosImplantationsComponent implements OnInit {
  title: string = 'Trouver une agence commerciale';
  image: string = './assets/images/header/header-bg.jpg';
  breadcrumb: any = [
    {
      label: 'Accueil',
      link: '/'
    },
    {
      label: 'Trouver une agence commerciale',
      link: '/nos-implantations'
    }
  ];

  implantations = [
    { lat: 48.117266, lng: -1.677793 },
    { lat: 47.218371, lng: -1.553621 },
    { lat: 48.856614, lng: 2.352222 },
    { lat: 49.443232, lng: 1.099971 },
    { lat: 49.182863, lng: -0.370679 },
    { lat: 48.649337, lng: -2.025674 },
    { lat: 48.514180, lng: -2.765835 },
  ];


  constructor() { }

  ngOnInit(): void {
  }
}
