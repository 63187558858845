import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './structure/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MapSiteComponent } from './structure/map-site/map-site.component';
import {NosImplantationsComponent} from "./pages/nos-implantations/nos-implantations.component";
import {NosRadiosComponent} from "./pages/nos-radios/nos-radios.component";
import {VosObjectifsComponent} from "./pages/vos-objectifs/vos-objectifs.component";
import {NousRejoindreComponent} from "./pages/nous-rejoindre/nous-rejoindre.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'nos-implantations', component: NosImplantationsComponent },
  { path: 'vos-objectifs', component: VosObjectifsComponent },
  { path: 'nous-rejoindre', component: NousRejoindreComponent },
  { path: 'sitemap-1-google.xml', component: MapSiteComponent },
  { path: 'contactez-nous', component: ContactComponent },
  { path: 'nos-radios', component: NosRadiosComponent },
  { path: 'nos-radios/:slug', component: NosRadiosComponent },
  { path: 'error', redirectTo: '' },
  { path: '**', component: PageNotFoundComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
