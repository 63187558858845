// APP
import { AppRoutingModule } from './app-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// _DIRECTIVES
import {HoverColorInverseDirective} from './_directives/hover-color-inverse.directive';

// _HELPERS
// import { authInterceptorProviders } from './_helpers/auth.interceptor';

// _PIPES
import {SlugifyPipe} from "./_pipes/slugify";

// STRUCTURE
import { HeaderComponent } from './structure/header/header.component';
import { FooterComponent } from './structure/footer/footer.component';
import { MenuComponent } from './structure/menu/menu.component';
import { ContactComponent } from './structure/contact/contact.component';

// COMPONENTS
import { CarouselComponent } from './components/carousel/carousel.component';

// PAGES
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { VosObjectifsComponent } from './pages/vos-objectifs/vos-objectifs.component';
import { NosImplantationsComponent } from './pages/nos-implantations/nos-implantations.component';
import { NosRadiosComponent } from './pages/nos-radios/nos-radios.component';
import { NousRejoindreComponent } from './pages/nous-rejoindre/nous-rejoindre.component';
import { DropDownComponent } from './structure/header/drop-down/drop-down.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import { RadioComponent } from './components/radio/radio.component';
import { RadioSelectComponent } from './components/radio-select/radio-select.component';


@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        HomeComponent,
        CarouselComponent,
        ContactComponent,
        HoverColorInverseDirective,
        SlugifyPipe,
        FooterComponent,
        PageHeaderComponent,
        VosObjectifsComponent,
        NosImplantationsComponent,
        NosRadiosComponent,
        NousRejoindreComponent,
        DropDownComponent,
        LeafletMapComponent,
        RadioComponent,
        RadioSelectComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CommonModule,
    FontAwesomeModule
  ],
  providers: [
    // authInterceptorProviders
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
