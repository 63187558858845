<div class="page-header" [style.background-image]="'url(' + image + ')'">
  <div class="page-header-title">
    {{title}}
  </div>
  <div class="breadcrumb-container">
    <div class="breadcrumb-item-wrapper">
      <a class="breadcrumb-item" *ngFor="let item of breadcrumb" [routerLink]="item.link">
        {{item.label}}
      </a>
    </div>
  </div>
  <div class="weird-shape"></div>
</div>
