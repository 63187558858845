import { Component, Input, OnInit } from '@angular/core';
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
  @Input() images: any[] = [] ;
  @Input() chevron: boolean = true;
  @Input() indicator: boolean = true;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.images)
  }

}
