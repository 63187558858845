import {Component, HostListener} from '@angular/core';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTwitter} from "@fortawesome/free-brands-svg-icons";
import { faFacebook} from "@fortawesome/free-brands-svg-icons";
import { faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  faYoutube = faYoutube;
  faTwitter = faTwitter;
  faFacebook = faFacebook;
  faLinkedin = faLinkedin;

  isScroll: boolean = false;
  navOpen: boolean = false;

  vosObjectifs: any[] = [
    { title: '',
      image: 'objectifs.svg',
      items: [
        { title: 'Développer votre image de marque', link: '/item-1' },
        { title: 'Générer du trafic en point de vente', link: '/item-2' },
        { title: 'Booster votre site web', link: '/item-3' },
        { title: 'Communiquer localement', link: '/item-4' },
        { title: 'Communiquer nationalement', link: '/item-5' }
      ]
    },
  ];

  nosSolution: any[] = [
    { title: 'Campagne / Publicité radio',
      image: 'campagne-pub-radio.svg',
      items: [
        { title: 'Le média radio', link: '/item-1' },
        { title: 'Publicité radio nationale', link: '/item-2' },
        { title: 'Publicité radio locale', link: '/item-3' },
        { title: 'Web diffusion', link: '/item-4' },
      ]
    },
    { title: 'Campagne / Audio Digitale',
      image: 'cloud-play.svg',
      items: [
        { title: 'L\'audio digital', link: '/item-1' },
        { title: 'Streaming', link: '/item-2' },
        { title: 'Webradios', link: '/item-3' },
        { title: 'Podcasts', link: '/item-4' },
      ]
    }
  ]
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if(screen.width < 1200) {
      this.isScroll = true;
    }
    else {
      this.isScroll = scrollPosition >= 60;
    }
  }

  toggleNav() {
    this.navOpen = !this.navOpen;
  }

  constructor() { }
}
