<app-carousel [images]="carouselImages" [indicator]="false"></app-carousel>

<section class="radios-section">
  <div class="radios-container">
    <div>
      <h2 class="title-h2-rrr">Expert en radio locale et nationale</h2>

      <div class="radio-logo">
        <img src="./assets/images/logo-radio/europe1.jpg" alt="europe-1">
        <img src="./assets/images/logo-radio/europe2.jpg" alt="europe-2">
        <img src="./assets/images/logo-radio/rfm.jpg" alt="rfm">
        <img src="./assets/images/logo-radio/Logo_Hit_West.jpg" alt="hit-west">
        <img src="./assets/images/logo-radio/oceane-fm.jpg" alt="oceane">
        <img src="./assets/images/logo-radio/Sweet%20FM%20Normandie.jpg" alt="sweet-fm">
        <img src="./assets/images/logo-radio/Logo_station_Alouette.jpg" alt="alouette">
        <img src="./assets/images/logo-radio/radio-bonheur.jpg" alt="radio-bonheur">
        <img src="./assets/images/logo-radio/logo-rba.jpg" alt="rba">
        <img src="./assets/images/logo-radio/Flash%20FM.jpg" alt="flash">
        <img src="./assets/images/logo-radio/logo-FGL-big.jpg" alt="fgl">
        <img src="./assets/images/logo-radio/FOREVER_72dpi.jpg" alt="mti">
        <img src="./assets/images/logo-radio/Radio%20Metropolys.jpg" alt="metropolys">
        <img src="./assets/images/logo-radio/radio-mti.jpg" alt="mti">
        <img src="./assets/images/logo-radio/Radio%20Nova%20Lyon%20-%20ville.jpg" alt="nova">
        <img src="./assets/images/logo-radio/Sea%20FM.jpg" alt="sea-fm">
        <img src="./assets/images/logo-radio/logo-vfm.jpg" alt="vfm">
        <img src="./assets/images/logo-radio/Logo_Sweet_FM.jpg" alt="vfm">
        <img src="./assets/images/logo-radio/logo-totem-radio.jpg" alt="totem">
      </div>
      <p>*Commercialisation d'Alouette à Angers, Château-Gontier, Jonzac, Laval, Mayenne, Royan, Saintes, Vannes ; FGL à Dax, Soustons ; Radio Métropolys à Amiens et Saint-Quentin ; MTI à Valence ; Radio Nova à Lyon ; Sea FM à Cherbourg ; Totem à Brive-la-Gaillarde, Cahors, Cassade, Gourdon, Montauban, Saint-Céré, Souillac, Tulle ; VFM à Bayeux »</p>
    </div>
    <div>
      <h2 class="title-h2-rrr">Expert en communication audio-digital</h2>
      <div class="radio-logo">
        <img src="./assets/images/logo-radio/europe1.jpg" alt="europe-1">
        <img src="./assets/images/logo-radio/europe2.jpg" alt="europe-2">
        <img src="./assets/images/logo-radio/rfm.jpg" alt="rfm">
        <img src="./assets/images/logo-radio/Logo_Hit_West.jpg" alt="hit-west">
        <img src="./assets/images/logo-radio/oceane-fm.jpg" alt="oceane">
        <img src="./assets/images/logo-radio/Sweet%20FM%20Normandie.jpg" alt="sweet-fm">
        <img src="./assets/images/logo-radio/Logo_station_Alouette.jpg" alt="alouette">
        <img src="./assets/images/logo-radio/radio-bonheur.jpg" alt="radio-bonheur">
        <img src="./assets/images/logo-radio/logo-rba.jpg" alt="rba">
        <img src="./assets/images/logo-radio/Flash%20FM.jpg" alt="flash">
        <img src="./assets/images/logo-radio/logo-FGL-big.jpg" alt="fgl">
        <img src="./assets/images/logo-radio/FOREVER_72dpi.jpg" alt="mti">
        <img src="./assets/images/logo-radio/Radio%20Metropolys.jpg" alt="metropolys">
        <img src="./assets/images/logo-radio/radio-mti.jpg" alt="mti">
        <img src="./assets/images/logo-radio/Radio%20Nova%20Lyon%20-%20ville.jpg" alt="nova">
        <img src="./assets/images/logo-radio/Sea%20FM.jpg" alt="sea-fm">
        <img src="./assets/images/logo-radio/logo-vfm.jpg" alt="vfm">
        <img src="./assets/images/logo-radio/Logo_Sweet_FM.jpg" alt="vfm">
        <img src="./assets/images/logo-radio/logo-totem-radio.jpg" alt="totem">
      </div>
    </div>
  </div>
</section>

<!--
<section class="expert-comm">
  <div class="expert-container">
    <h1 class="title-h1-rrr">Expert en communication Audio</h1>

    <div class="card-container">
      <div class="card">
        <img class="card-img" src="./assets/images/25-ans.png">
        <h2><span>+ 25</span> ANNÉES <br/>D'EXPERTISE</h2>
      </div>

      <div class="card">
        <img class="card-img" src="./assets/images/frequence-locale.png">
        <h2><span>+ 200</span> Fréquences <br/>Locales</h2>
      </div>

      <div class="card">
        <img class="card-img" src="./assets/images/villes-couverte.png">
        <h2><span>+ 150</span> Villes <br/> couvertes</h2>
      </div>

      <div class="card">
        <img class="card-img" src="./assets/images/client-annee.png">
        <h2><span>+ 6000</span> clients <br/>chaque année</h2>
      </div>
    </div>

    <a class="btn-rrr" href="#">Contactez-nous</a>
  </div>
</section>
-->

<section class="expert-comm-2">
  <div class="expert-comm-container">
    <h1 class="title-h1-rrr">Des experts en communication audio proche de vous</h1>
    <a class="btn-rrr" href="#">Trouver une agence</a>

    <div class="expert-content">
      <img src="./assets/images/Carte%20de%20France.png">

      <div class="expert-text">
        <p>
          <span>Régie Radio Régions</span> est la 2e régie publicitaire de radios locales et nationales en région.
          <br/>
          <br/>
          25 ans d’expérience dans le média radio avec un réseau de 60 agences commerciales en région, plus de 130 collaborateurs et une offre de plus de 200 fréquences radios sur plus 150 villes en France, Régie Radio Régions propose à ses annonceurs de multiples solutions de communication <span>Radio et Audio-Digitale</span> en local, en multiville et au national.
          <br/>
          <br/>
          <span>Régie Radio Régions</span> propose également des solutions <span>évènementielles</span>, <span>marketing direct</span> (RPhone) et de production sonore avec <span>2 studios professionnels de création</span>.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="projets-clients">
  <h1 class="title-h1-rrr text-center">Quelques-uns de nos projets clients</h1>

  <div class="projet-grid">
    <a href="#" class="projet">
      <div class="filter"></div>
      <img class="projet-img" src="./assets/images/test-projet-client.jpg">
      <div class="text-projet">
        <a>Lire la suite</a>
        <h2>Projets clients</h2>
      </div>
    </a>

    <a href="#" class="projet">
      <div class="filter"></div>
      <img class="projet-img" src="./assets/images/test-projet-client.jpg">
      <div class="text-projet">
        <a>Lire la suite</a>
        <h2>Projets clients</h2>
      </div>
    </a>

    <a href="#" class="projet">
      <div class="filter"></div>
      <img class="projet-img" src="./assets/images/test-projet-client.jpg">
      <div class="text-projet">
        <a>Lire la suite</a>
        <h2>Projets clients</h2>
      </div>
    </a>
  </div>
</section>
