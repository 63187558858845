<div class="nav-container">
  <div class="nav-top" [ngClass]="{'scroll-padding': isScroll}">
    <a href="tel:0299264561" class="link-sm">Service commercial : 02 99 26 45 61</a>
    <div class="socials">
      <fa-icon class="icon" [icon]="faTwitter"></fa-icon>
      <fa-icon class="icon" [icon]="faFacebook"></fa-icon>
      <fa-icon class="icon" [icon]="faLinkedin"></fa-icon>
      <fa-icon class="icon" [icon]="faYoutube"></fa-icon>
    </div>
    <a routerLink="#" class="link contact">
      Nous contacter
      <img src="./assets/images/logo-radio/icon-mail.jpg" />
    </a>
    <a routerLink="/nos-implantations" class="link contact">
      Nos implantations
      <img src="./assets/images/elements/marker/marker-map.png" />
    </a>
  </div>
  <div class="nav-wrapper" [ngClass]="{'scroll' : isScroll}">
    <nav [ngClass]="{'active': navOpen}">
      <img routerLink="/" src="./assets/images/logo.png" alt="logo" class="logo">

      <div class="link-wrapper">
        <app-drop-down
          [title]="'Radio'"
          [items]="vosObjectifs"
          [index]="0">
        </app-drop-down>
        <a routerLink="#"  class="link">Création sonore</a>
        <a routerLink="#"  class="link">Digital</a>
        <app-drop-down
          [title]="'Evènementiel'"
          [items]="nosSolution"
          [index]="1">
        </app-drop-down>
        <a routerLink="#"  class="link">RPhone</a>
      </div>

      <div class="links-menu" [ngClass]="{'active': navOpen}">
        <div class="hamburger hamburger-menu menu" (click)="toggleNav()">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <img routerLink="/" src="./assets/images/logo.png" alt="logo" class="logo-menu">
        <div class="links-menu-inner">
          <div class="links-wrapper">
            <app-drop-down
              [title]="'Radio'"
              [items]="vosObjectifs"
              [index]="0">
            </app-drop-down>
            <a routerLink="#"  class="link">Création sonore</a>
            <a routerLink="#"  class="link">Digital</a>
            <app-drop-down
              [title]="'Evènementiel'"
              [items]="nosSolution"
              [index]="1">
            </app-drop-down>
            <a routerLink="#"  class="link">RPhone</a>
          </div>
          <div class="nav-top-mobile">
            <a href="tel:0299264561" class="service">Service commercial : 02 99 26 45 61</a>
            <div class="socials">
              <fa-icon class="icon" [icon]="faTwitter"></fa-icon>
              <fa-icon class="icon" [icon]="faFacebook"></fa-icon>
              <fa-icon class="icon" [icon]="faLinkedin"></fa-icon>
              <fa-icon class="icon" [icon]="faYoutube"></fa-icon>
            </div>
            <a routerLink="#" class="link contact">
              Nous contacter
              <img src="./assets/images/logo-radio/icon-mail.jpg" />
            </a>
          </div>
        </div>
      </div>

      <div class="hamburger" (click)="toggleNav()">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  </div>
  <img src="./assets/images/elements/Barre-background-header.svg" alt="barre" class="barre">
</div>
