<app-page-header [title]="title" [image]="image" [breadcrumb]="breadcrumb">
</app-page-header>

<section class="nous-rejoindre-section">
  <div class="container-70">
    <h1 class="title-border-bottom">Pourquoi nous rejoindre ?</h1>
    <p class="text-rrr">
      C’est un <span>engagement</span> réciproque dans lequel le collaborateur <span>s’implique</span>, apporte sa <span>contribution</span>, sa <span>personnalité</span>,
      ses <span>idées</span>… et le groupe qui accompagne, crée les conditions pour <span>se révéler</span> et <span>s'épanouir</span>. Venez goûter à
      <span>l’autonomie</span> et développer votre <span>employabilité</span> !
    </p>
  </div>
</section>
<section class="section-mot-du-directeur">
  <div class="stats">
    <div class="square">
      <p class="square-title">60</p>
      <p class="square-text">agences en région</p>
    </div>

    <div class="square right">
      <p class="square-title">40</p>
      <p class="square-text">nouveaux collaborateurs <br/>intégrés en 2022</p>
    </div>
  </div>

  <div class="container-70">
    <div class="mot-du-directeur">
      <div class="avatar"></div>
      <div>
        <h1>Le mot du Directeur des Ressources Humaines</h1>
        <p class="text-rrr">
          Notre  <span>politique RH</span> témoigne de notre sens de la <span>responsabilité sociale</span> de l'entreprise. Au quotidien,
          la proximité dans les <span>relations humaines</span> est le véritable facteur de sens de nos équipes.

          <br/><br/>En termes de <span>management</span>, la <span>bienveillance</span> de <span>l’encadrement</span> opérationnel et la diversité dans nos
          recrutements sont mis en avant par nos équipes : <span>diversité</span> des profils, des expériences, des parcours…

          <br/><br/>L’ambiance s’en ressent : elle est marquée par le plaisir de collaborer ensemble.

          <br/><br/><span>Julien Goudet, directeur des ressources humaines</span>
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-investir">
  <p>Investir nos métiers</p>
  <div class="candidature-rectangle-wrapper">
    <div class="candidature-rectangle-2">
      <p>Nos offres d'emploi</p>
      <a>Je consulte</a>
    </div>
    <div class="candidature-rectangle-1">
      <p>Candidature spontannée</p>
      <a>Je dépose mon cv</a>
    </div>
  </div>
</section>

<section class="nous-rejoindre-section">
  <div class="container-70">
    <h1 class="title-border-bottom">Nous rejoindre</h1>

    <h1 class="title-text">Un groupe solide et reconnu au cœur de la vie des territoires.</h1>
    <p class="text-rrr">
      <span>Régie Radio Régions est une filiale du Groupe Additi – Ouest France et Lagardère.</span>

      Partenaires reconnus des acteurs
      économiques, culturels, sociaux et institutionnels, les différentes entités du groupe contribuent à la
      communication locale, régionale et nationale des acteurs économiques des territoires.
      <br/>Nos 135 collaborateurs s’appliquent chaque jour à avoir un impact utile dans la communication de proximité
      des annonceurs. C’est pourquoi l’amabilité, la réactivité et la qualité de service de nos collaborateurs sont
      plébiscités par
      nos clients.
    </p>

    <h1 class="title-text">Un groupe orienté clients & collaborateurs</h1>
    <p class="text-rrr">
      C’est ensemble que nous grandissons. Chez Régie Radio Régions, les entretiens professionnels et annuels se font
      tous les ans afin de détecter les besoins en formation des collaborateurs et donner la possibilité d’être mobile
      dans le groupe quand il y a des opportunités. Nous démontrons chaque jour que la force du collectif et la
      bienveillance managériale sont autant d’atouts qui font avancer l’entreprise. En valorisant les personnalités
      et les idées de chacun, partout et au quotidien, c’est autant d’opportunités de passer de l'idée à l'action !
    </p>

    <h1 class="title-text">Un groupe qui sait se réinventer</h1>
    <p class="text-rrr">
      Notre vision et notre objectif, ambitieux autant que fédérateurs, offrent un rôle à jouer à tous nos
      collaborateurs.
      <br/>Chez nous, la réussite de tous passe aussi par la réussite de chacun.
    </p>
  </div>
</section>
