import { Component, OnInit } from '@angular/core';
import { itemSitemap } from '../../_models/itemSitemap';
import { UrlService } from '../../_services/url.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-map-site',
  templateUrl: './map-site.component.html',
  styleUrls: ['./map-site.component.scss']
})
export class MapSiteComponent implements OnInit {

  urls: itemSitemap[] = [];
  xml: string = '<?xml version="1.0" encoding="UTF-8" ?> <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"  xmlns:xhtml="http://www.w3.org/1999/xhtml"  xmlns:image="http://www.google.com/schemas/sitemap-image/1.1"  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"  xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9  http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">';
  // result : SafeHtml;
  constructor(private siteMapService: UrlService) {
    // this.result = this.sanitizer.bypassSecurityTrustHtml(this.xml);

  }

  public getUrl(){
    this.siteMapService.getRoute().subscribe(
      (urls) => this.urls = urls
    );
    return this.urls;
  }

  ngOnInit(): void {
  }

}
