import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {json} from "express";

@Component({
  selector: 'app-radio-select',
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.scss']
})
export class RadioSelectComponent implements OnInit {
  @Input()
  radios: any[] = [];

  @Input()
  selectedRadio: any;

  @Output()
  radioChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      if (slug) {
        this.selectedRadio = this.radios.find(radio => radio.slug === slug);
      }
    });
  }


  selectRadio(radio: any): void {
    this.selectedRadio = radio;
    this.emitRadioChange();
    this.updateRoute();
  }

  private updateRoute(): void {
    const slug = this.selectedRadio.slug;
    if (slug){
      this.router.navigate(['/nos-radios', slug]);
    }
  }



  private emitRadioChange(): void {
    this.radioChange.emit(this.selectedRadio);
  }
}
