<div class="dropdown-wrapper">
  <a class="link" (click)="toggleDropdown()">
    {{title}}

    <div class="chevron">
      <div></div>
      <div></div>
    </div>
  </a>
  <div class="dropdown-grid" [class.dropdown-show]="isOpen" >
    <div class="dropdown" *ngFor="let item of items">
      <div class="dropdown-image">
        <img src="assets/images/elements/nav/{{item.image}}"/>
      </div>
      <p>{{item.title}}</p>
      <a href="#" *ngFor="let i of item.items">{{i.title}}</a>
    </div>
  </div>
</div>
