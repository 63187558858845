import {Component, Input, OnInit} from '@angular/core';

interface Breadcrumb {
  label: string;
  link: string;
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input()
  title: string = '';

  @Input()
  image: string = '';

  @Input()
  breadcrumb: Breadcrumb[] = [
    {
      label: 'Accueil',
      link: '/'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
