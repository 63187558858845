import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vos-objectifs',
  templateUrl: './vos-objectifs.component.html',
  styleUrls: ['./vos-objectifs.component.scss']
})
export class VosObjectifsComponent implements OnInit {

  title: string = 'Développer votre image de marque';
  image: string = './assets/images/header/header-bg-marque.jpg';
  breadcrumb: any = [
    {
      label: 'Accueil',
      link: '/'
    },
    {
      label: 'Vos objectifs',
      link: '/vos-objectifs'
    },
    {
      label: 'Développer votre image de marque',
      link: '/vos-objectifs/developper-image-marque'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
