<div id="carouselIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators" *ngIf="indicator">
    <button
      type="button"
      data-bs-target="#carouselIndicators"
      aria-current="true"
      *ngFor="let image of images; let i = index; let first = first"
      [attr.aria-label]="'Slide ' + (i+1)"
      [attr.data-bs-slide-to]="i"
      [ngClass]="{ active: first }">
    </button>
  </div>
  <div class="carousel-inner">
<!--    <div class="carousel-item" *ngFor="let image of images; let i = index; let first = first" [ngClass]="{ active: first }">-->

<!--      <div class="slider-text">-->
<!--        <h1>Communication Radio et Audio-Digitale</h1>-->
<!--        <a class="btn-rrr" routerLink="{{image?.lien}}">Contactez votre agence locale</a>-->
<!--      </div>-->

<!--      <img src="{{image?.urlImage}}" class="d-block w-100" alt="...">-->
<!--    </div>-->

    <div class="carousel-item active">
      <div class="slider-text">
        <h1>Communication Radio et Audio-Digitale</h1>
        <a class="btn-rrr" routerLink="/">Contactez votre agence locale</a>
      </div>

      <img src="./assets/images/slider-1.jpg" class="d-block w-100" alt="slider-1">
    </div>

    <div class="carousel-item">
      <div class="slider-text">
        <h1>Communication Radio et Audio-Digitale</h1>
        <a class="btn-rrr" routerLink="/">Contactez votre agence locale</a>
      </div>

      <img src="./assets/images/slider-1.jpg" class="d-block w-100" alt="slider-1">
    </div>
  </div>

  <div class="slider-arrow">
    <button class="arrow-left" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>
    <button class="arrow-right" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
