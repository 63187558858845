<div class="contact">
  <h1>Besoin d'un renseignement sur nos stations ?</h1>
    <form #contactForm="ngForm" (ngSubmit)="onFormSubmit(contactForm)">
        <div class="form-group row">
          <div class="col-6">
            <input type="text" ngModel name="nom" id="nom" class="form-control" placeholder="Nom*">
          </div>
          <div class="col-6">
            <input type="text" ngModel name="prenom" id="prenom" class="form-control" placeholder="Prénom*">
          </div>

          <div class="col-6">
            <input type="text" ngModel name="societe" id="societe" class="form-control" placeholder="Société*">
          </div>
          <div class="col-6">
            <input type="text" ngModel name="tel" id="tel" class="form-control" placeholder="Téléphone*">
          </div>
          <div class="col-12">
            <input type="text" ngModel name="email" id="email" class="form-control" placeholder="Email*">
          </div>
          <div class="col-12">
            <input type="text" ngModel name="sujet" id="sujet" class="form-control" placeholder="Sujet">
          </div>
          <div class="col-12">
            <textarea type="text" ngModel name="message" id="message" class="form-control" placeholder="Votre message"></textarea>
          </div>

          <div class="col-12  d-flex justify-content-center">
            <a class="btn-rrr" href="#">Envoyer</a>
          </div>
        </div>

      <p class="legals">
        Vos données personnelles font l’objet d’un traitement informatique par la Société EUROPE RÉGIES OUEST, sur le
        fondement de votre consentement et sont utilisées pour répondre à vos demandes d’informations. Elles seront
        conservées pendant 3 ans à compter de la collecte des données sauf dispositions légales particulières.
        Vous disposez d’un droit d’accès, de rectification, d’opposition, de limitation et de portabilité, en vous
        adressant directement à pdp@sipa.ouest-france.fr ou par courrier à « Délégué à la Protection des Données
        Personnelles SIPA Ouest-France – ZI Rennes Sud-Est – 10 rue du Breil – 35051 Rennes cedex 9 ». Vous avez
        également le droit d’introduire une réclamation auprès de la CNIL. En savoir plus. N’hésitez plus.
      </p>
    </form>
</div>
