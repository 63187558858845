import { Component, OnInit } from '@angular/core';
import { RadioService} from "../../_services/radio.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-nos-radios',
  templateUrl: './nos-radios.component.html',
  styleUrls: ['./nos-radios.component.scss']
})
export class NosRadiosComponent implements OnInit {
  image = './assets/images/header/header-bg.jpg';
  slug = 'europe-1';
  slugTitle = 'Faire de la publicité sur ';

  radios: any[] = [];
  selectedRadio: any = {};

  breadcrumb: any = [
    { label: 'Accueil', link: '/' },
    { label: 'Nos radios', link: '/nos-radios' },
    { label: this.slugTitle + this.selectedRadio.name, link: '/nos-radios/' + this.slug, active: true }
  ];

  constructor(private radioService: RadioService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.fetchRadios();
  }

  fetchRadios(): void {
    this.radioService.getRadios().subscribe((radios) => {
      this.radios = radios;
      const slugFromUrl = this.route.snapshot.paramMap.get('slug');
      if (slugFromUrl) {
        this.selectedRadio = radios.find(radio => radio.slug === slugFromUrl);
        console.log(this.selectedRadio);
      } else {
        this.selectedRadio = radios[0];
      }
    });
  }


  selectedChange(radio: any): void {
    this.selectedRadio = radio;
  }
}
