import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nous-rejoindre',
  templateUrl: './nous-rejoindre.component.html',
  styleUrls: ['./nous-rejoindre.component.scss']
})
export class NousRejoindreComponent implements OnInit {

  title: string = 'Rejoindre Régie Radio Régions';
  image: string = './assets/images/header/header-bg.jpg';
  breadcrumb: any = [
    {
      label: 'Accueil',
      link: '/'
    },
    {
      label: 'Nous rejoindre',
      link: '/nous-rejoindre'
    },
    {
      label: 'Pourquoi nous rejoindre ?',
      link: '/nous-rejoindre/pourquoi-nous-rejoindre'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
