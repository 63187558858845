import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {
  @Input('title') title: string = '';
  @Input('index') index: number = -1;
  @Input('items') items: any[] = [];

  isOpen: boolean = false;
  static openedDropdownIndex: number | null = null;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void { }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    DropDownComponent.openedDropdownIndex = this.isOpen ? this.index : null;
    const chevron = this.elementRef.nativeElement.querySelector('.chevron');
    this.renderer.addClass(chevron, 'rotate');
    if (!this.isOpen) {
      this.renderer.removeClass(chevron, 'rotate');
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (DropDownComponent.openedDropdownIndex === this.index) {
        this.isOpen = false;
        DropDownComponent.openedDropdownIndex = null;
        const chevron = this.elementRef.nativeElement.querySelector('.chevron');
        this.renderer.removeClass(chevron, 'rotate');
      }
    }
  }
}
