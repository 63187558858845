import { Component, OnInit } from '@angular/core';
import {CarouselService} from "../../_services/carousel.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  carouselImages : any;

  constructor(private carouselService: CarouselService) {
  }

  ngOnInit(): void {
    this.getCarousel();
  }

  getCarousel(){
    this.carouselService.getCarousel().subscribe((res: any) => {
      this.carouselImages = res;
      console.log(this.carouselImages);
    });
  }
}
