<app-page-header [title]="selectedRadio.name" [image]="image" [breadcrumb]="breadcrumb">
</app-page-header>

<app-radio-select [radios]="radios" [selectedRadio]="selectedRadio" (change)="selectedChange($event)">
</app-radio-select>

<app-radio [radio]="selectedRadio">
</app-radio>

<app-contact>
</app-contact>
