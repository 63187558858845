<footer>
  <img class="element-footer" src="./assets/images/elements/barre-footer.svg">

  <div class="footer-container">
    <div class="footer-col">
      <img src="./assets/images/logo.png" >
      <a routerLink="#" class="btn-rrr">Rejoignez-nous</a>
    </div>
    <div class="footer-col">
      <p class="footer-title">Nos implantations</p>
      <div class="footer-col-wrapper">
        <a href="#">Rennes</a>
        <a href="#">Nantes</a>
        <a href="#">Rouen</a>
        <a href="#">Bordeaux</a>
        <a href="#">Toulouse</a>
        <a href="#">Nice</a>
        <a href="#">Biarritz</a>
        <a href="#">Lille</a>
        <a href="#">Marseille</a>
        <a href="#">Lyon</a>
        <a href="#">Strasbourg</a>
        <a href="#">Caen</a>
        <a href="#">Brest</a>
        <a href="#">Angers</a>
        <a href="#">Grenoble</a>
        <a href="#">Le Mans</a>
        <a href="#">Limoges</a>
        <a href="#">Perpignan</a>
        <a href="#">Nancy</a>
        <a href="#">Metz</a>
        <a href="#">La Roche sur yon</a>
      </div>
    </div>

    <div class="footer-col">
      <p class="footer-title">Nos radios</p>
      <div class="footer-col-wrapper">
        <a href="#">Rennes</a>
        <a href="#">Nantes</a>
        <a href="#">Rouen</a>
        <a href="#">Bordeaux</a>
        <a href="#">Toulouse</a>
        <a href="#">Nice</a>
        <a href="#">Biarritz</a>
        <a href="#">Lille</a>
        <a href="#">Marseille</a>
        <a href="#">Lyon</a>
        <a href="#">Strasbourg</a>
        <a href="#">Caen</a>
        <a href="#">Brest</a>
        <a href="#">Angers</a>
        <a href="#">Grenoble</a>
        <a href="#">Le Mans</a>
        <a href="#">Limoges</a>
        <a href="#">Perpignan</a>
        <a href="#">Nancy</a>
        <a href="#">Metz</a>
        <a href="#">La Roche sur yon</a>
      </div>
    </div>

    <div class="footer-col">
      <p>Restez à l'écoute</p>
      <div class="footer-col-wrapper">
        <div class="newsletter">
          <input type="text" placeholder="Votre adresse e-mail">
          <button class="btn-send">
            <fa-icon [icon]="faPlane"></fa-icon>
          </button>
        </div>
        <p class="consent">
          Votre e-mail, avec votre consentement, est utilisé par Régie Radio Régions. Vous aurez la possibilité de
          sélectionner des newsletters qui correspondent à vos centres d'intérêts. <a href="#">En savoir plus.</a>
        </p>
      </div>
    </div>
  </div>

  <div class="socials">
    <a href="#"><fa-icon [icon]="faFacebook"></fa-icon></a>
    <a href="#"><fa-icon [icon]="faTwitter"></fa-icon></a>
    <a href="#"><fa-icon [icon]="faInstagram"></fa-icon></a>
    <a href="#"><fa-icon [icon]="faYoutube"></fa-icon></a>
  </div>
  <div class="legals">
    <a>Agence 11H10 Web & Com'</a>

    <div class="d-flex">
      <a href="#">Mentions légales  /</a>
      <a href="#"> Politique de confidentialité  /</a>
      <a href="#"> Plan du site  /</a>
      <a href="#"> Cookies</a>
    </div>
  </div>
</footer>
