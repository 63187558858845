import { Component, OnInit, Input } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit {
  @Input() implantations: { lat: number; lng: number }[] = [];

  private map!: L.Map;
  private markers!: L.MarkerClusterGroup;

  searchInput = '';

  async ngOnInit() {
    this.initMap();
    await this.addImplantationMarkers();
  }

  initMap(): void {
    this.map = L.map('map').setView([48.117266, -1.677793], 8);

    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a> contributors'
    }).addTo(this.map);
  }

  async addImplantationMarkers(): Promise<void> {
    await import('leaflet.markercluster');
    this.markers = (L as any).markerClusterGroup();

    const customIcon = L.icon({
      iconUrl: 'assets/images/elements/marker/marker-map.png',
      iconSize: [45, 37],
      iconAnchor: [10, 37],
    });

    this.implantations.forEach(implantation => {
      const marker = L.marker([implantation.lat, implantation.lng], { icon: customIcon })
      marker.bindPopup(
        `<div class="implantation-box">
                    <a href="#" class="implantation-box__title">Agence de rennes</a>
                    <div class="implantation-box__address">2 rue de la Mabilais</div>
                    <div class="implantation-box__address">35000 Rennes</div>
                </div>`
      );
      this.markers.addLayer(marker);
    });

    this.map.addLayer(this.markers);
  }

  async searchLocation(): Promise<void> {
    try {
      const coordinates = await this.getGeolocation(this.searchInput);

      this.map.zoomOut(2);

      setTimeout(() => {
        this.map.flyTo([coordinates.lat, coordinates.lon], 11, {
          animate: true,
          duration: 1
        });
      }, 800);

    } catch (err) {
      console.error(err);
    }
  }



  getGeolocation(postalCode: string): Promise<{ lat: number, lon: number }> {
    return new Promise((resolve, reject) => {
      fetch(`https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&format=json&countrycodes=fr`)
        .then(response => response.json())
        .then(data => {
          if (data && data[0]) {
            resolve({ lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon) });
          } else {
            reject('No data found');
          }
        })
        .catch(err => reject(err));
    });
  }

}
