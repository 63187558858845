import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RadioService {

  api = environment.api;

  constructor(private http: HttpClient) {}

  getRadios(): Observable<any[]> {
    //return this.http.get<any[]>(`${this.api}/radios`);

    return this.http.get<any[]>('./assets/data/radios.json');
  }
}
