

<div class="input-search-wrapper">
  <p>Trouver l'agence la plus proche de chez vous !</p>

  <div>
    <label for="search" class="search-label">Code postal :</label>
    <input id="search" class="search-input" [(ngModel)]="searchInput" (ngModelChange)="searchLocation()" placeholder="35000">
  </div>

</div>

<div id="map" class="map-container"></div>
